<div class="page-referral ticker-none">
    <app-header activeMenu="referral" [latestNewsMobile]="false" />
    <div class="page-inner">
        <div class="container-xxl row m-auto">
            <div class="col-lg-2">
                <app-sidebar-menu [active]="'referral'" />
            </div>
            <div class="page-inner-container col-lg-10 col-12">
                <div class="title mb-2">
                    REFERRAL
                </div>
                @if(islogin) {
                <div class="bg-blue p-4 mb-4 rounded">
                    <div class="bg-blue2 p-4 text-center text-yellow mb-4 rounded text-referral">
                        {{getReferralPlayer.data.referral_code}}
                    </div>
                    <div class="text-center">
                        <div class="btn btn-sm btn-yellow2 py-1 px-4 mx-1 mb-2"
                            (click)="copyToClipboard(getReferralPlayer.data.referral_code)">
                            SALIN KODE <fa-icon [icon]="faLink"></fa-icon>
                        </div>
                        <div class="btn btn-sm btn-yellow2 py-1 px-4 mx-1 mb-2"
                            (click)="changeReferralCode(getReferralPlayer.data.referral_code)">
                            UBAH KODE
                        </div>
                    </div>
                </div>
                <div class="mb-4 d-lg-flex d-block justify-content-center text-lg-center">
                    <span class="text-white me-2 mb-lg-0 mb-2 d-lg-flex d-block flex-column justify-content-center">
                        Kode Referral
                    </span>
                    <span class="bg-blue py-lg-2 px-lg-4 p-4 text-yellow rounded d-block text-lg-left text-center">
                        <span
                            class="me-lg-4 mb-lg-0 mb-2 d-lg-inline d-block">{{baseUrl+'/register?referral='+getReferralPlayer.data.referral_code}}</span>
                        <div class="btn btn-sm btn-yellow2 py-1 px-4 mt-1"
                            (click)="copyToClipboard(baseUrl+'/register?referral='+getReferralPlayer.data.referral_code)">
                            SALIN <fa-icon [icon]="faLink"></fa-icon>
                        </div>
                    </span>
                </div>
                <div class="mb-4 row gx-4 claim-info">
                    <div class="col col-lg-3 col-md-6 col-12 d-flex px-3">
                        <div class="rounded bg-blue-linear-vertical p-4 text-center w-100 claim-info-inner">
                            @if(getReferralPlayer === null) {
                            <div class="text-white mt-4 spinner-border p-2 spinner-border-sm"></div>
                            } @else {
                            <div class="m-auto d-table text-white py-2 px-3 rounded bg-blue icon-wallet bg-blue3">
                                <fa-icon [icon]="faWallet"></fa-icon>
                            </div>
                            <div class="text-white">
                                Total<br />
                                Bonus
                            </div>
                            <div class="text-blue2">
                                IDR {{getReferralPlayer.data.earning | formatNumberId}}
                            </div>
                            }
                        </div>
                    </div>
                    <div class="col col-lg-3 col-md-6 col-12 d-flex px-3">
                        <div class="rounded bg-blue-linear-vertical p-4 text-center w-100 claim-info-inner">
                            @if(getReferralPlayer === null) {
                            <div class="text-white mt-4 spinner-border p-2 spinner-border-sm"></div>
                            } @else {
                            <div class="m-auto d-table text-white py-2 px-3 rounded bg-blue icon-wallet bg-blue3">
                                <fa-icon [icon]="faWallet"></fa-icon>
                            </div>
                            <div class="text-white">
                                Total<br />
                                Claim
                            </div>
                            <div class="text-blue2">
                                IDR {{getReferralPlayer.data.claimed_earning | formatNumberId}}
                            </div>
                            }
                        </div>
                    </div>
                    <div class="col col-lg-3 col-md-6 col-12 d-flex px-3">
                        <div class="rounded bg-blue-linear-vertical p-4 text-center w-100 claim-info-inner">
                            @if(getReferralPlayer === null) {
                            <div class="text-white mt-4 spinner-border p-2 spinner-border-sm"></div>
                            } @else {
                            <div class="m-auto d-table text-white py-2 px-3 rounded bg-blue icon-wallet bg-blue3">
                                <fa-icon [icon]="faWallet"></fa-icon>
                            </div>
                            <div class="text-white">
                                Bonus yang bisa<br />
                                diklaim
                            </div>
                            <div class="text-blue2">
                                IDR {{getReferralPlayer.data.earning - getReferralPlayer.data.claimed_earning |
                                formatNumberId}}
                            </div>
                            <div class="btn btn-sm btn-yellow2 py-1 px-4 mt-4" (click)="popupOpen('claim')">
                                KLAIM
                            </div>
                            }
                        </div>
                    </div>
                    <div class="col col-lg-3 col-md-6 col-12 d-flex px-3">
                        <div class="rounded bg-blue-linear-vertical p-4 text-center w-100 claim-info-inner">
                            <div class="m-auto d-table text-white py-2 px-3 rounded bg-blue icon-wallet bg-blue3">
                                <fa-icon [icon]="faWallet"></fa-icon>
                            </div>
                            <div class="text-white">
                                Total<br />
                                Downline
                            </div>
                            <div class="text-blue2">
                                IDR 0
                            </div>
                            <div class="btn btn-sm btn-yellow2 py-1 px-4 mt-4" (click)="popupOpen('detail')">
                                DETAIL
                            </div>
                        </div>
                    </div>
                </div>
                }
                @if(this.referralRule !== null) {
                <div class="row gx-2 mt-1">
                    <div class="col col-lg-6 col-12 px-3 mb-4">
                        <div class="row row-cols-3 gx-0 bg-blue-linear-vertical2 px-2 pt-2 pb-5 card-rule rounded">
                            <div class="col p-1">
                                <div class="title-rule text-center p-1">
                                    Tipe Permainan
                                </div>
                            </div>
                            <div class="col p-1">
                                <div class="title-rule text-center p-1">
                                    Rollingan
                                </div>
                            </div>
                            <div class="col p-1">
                                <div class="title-rule text-center p-1">
                                    Menang/Kalah
                                </div>
                            </div>
                            <div class="col p-1">
                                <div class="bg-blue4 text-white rounded text-center p-1">
                                    Fish Hunter
                                </div>
                            </div>
                            <div class="col p-1">
                                <div class="bg-blue4 text-white rounded text-center p-1">
                                    {{referralRule.rules['FH'].turnover_percentage | formatNumberId}}%
                                </div>
                            </div>
                            <div class="col p-1">
                                <div class="bg-blue4 text-white rounded text-center p-1">
                                    {{referralRule.rules['FH'].winlose_percentage | formatNumberId}}%
                                </div>
                            </div>
                            <div class="col p-1">
                                <div class="bg-blue4 text-white rounded text-center p-1">
                                    Live Casino
                                </div>
                            </div>
                            <div class="col p-1">
                                <div class="bg-blue4 text-white rounded text-center p-1">
                                    {{referralRule.rules['LC'].turnover_percentage | formatNumberId}}%
                                </div>
                            </div>
                            <div class="col p-1">
                                <div class="bg-blue4 text-white rounded text-center p-1">
                                    {{referralRule.rules['LC'].winlose_percentage | formatNumberId}}%
                                </div>
                            </div>
                            <div class="col p-1">
                                <div class="bg-blue4 text-white rounded text-center p-1">
                                    Sportbooks
                                </div>
                            </div>
                            <div class="col p-1">
                                <div class="bg-blue4 text-white rounded text-center p-1">
                                    {{referralRule.rules['SB'].turnover_percentage | formatNumberId}}%
                                </div>
                            </div>
                            <div class="col p-1">
                                <div class="bg-blue4 text-white rounded text-center p-1">
                                    {{referralRule.rules['SB'].winlose_percentage | formatNumberId}}%
                                </div>
                            </div>
                            <div class="col p-1">
                                <div class="bg-blue4 text-white rounded text-center p-1">
                                    Slots
                                </div>
                            </div>
                            <div class="col p-1">
                                <div class="bg-blue4 text-white rounded text-center p-1">
                                    {{referralRule.rules['SL'].turnover_percentage | formatNumberId}}%
                                </div>
                            </div>
                            <div class="col p-1">
                                <div class="bg-blue4 text-white rounded text-center p-1">
                                    {{referralRule.rules['SL'].winlose_percentage | formatNumberId}}%
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col col-lg-6 col-12 px-3">
                        <div class="card card-rule">
                            <h5 class="card-title bg-blue-linear-vertical2 text-center font-italic color-yellow2 p-3">
                                <fa-icon [icon]="faCircleInfo" class="me-2"></fa-icon> <span>CATATAN</span>
                            </h5>
                            <div class="card-body text-white bg-blue-linear-vertical py-lg-4 px-lg-5 py-2 px-2">
                                <div class="mb-3 text-note font-italic">*Maks pendapatan harian
                                    @if(referralRule.earning_rules.max_daily_earning) {
                                    {{ referralRule.earning_rules.max_daily_earning | formatNumberId }}
                                    } @else {
                                    UNLIMITED
                                    }
                                </div>
                                <div class="mb-3 text-note font-italic">***Maks pendapatan mingguan
                                    @if(referralRule.earning_rules.max_weekly_earning) {
                                    {{ referralRule.earning_rules.max_weekly_earning | formatNumberId }}
                                    } @else {
                                    UNLIMITED
                                    }
                                </div>
                                <div class="mb-3 text-note font-italic">**Maks pendapatan bulanan
                                    @if(referralRule.earning_rules.max_monthly_earning) {
                                    {{ referralRule.earning_rules.max_monthly_earning | formatNumberId }}
                                    } @else {
                                    UNLIMITED
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                }
            </div>
        </div>
    </div>
    <app-footer [data]="footerData" />
</div>