import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { HeaderComponent } from "@template/mps-basic/header/header.component";
import { FooterComponent } from '@template/mps-basic/footer/footer.component';
import { DataService } from '@componentpath/services/data.service';
import { AuthService } from '@componentpath/services/auth.service';
import { RequestService } from '@componentpath/services/request.service';
import { SidebarMenuComponent } from '@template/mps-basic/parts/sidebar-menu/sidebar-menu.component';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat)
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCircleChevronLeft, faCircleChevronRight } from '@fortawesome/free-solid-svg-icons';
import { interval, Subscription } from 'rxjs';
import { FormatNumberIdPipe } from '@componentpath/pipes/format-number-id.pipe';
import { FormatDateIdPipe, FormatDateFull2IdPipe, FormatDateFull3IdPipe } from '@componentpath/pipes/format-date-id.pipe';

@Component({
  selector: 'app-statement',
  standalone: true,
  imports: [HeaderComponent, FooterComponent, SidebarMenuComponent, FontAwesomeModule, FormatNumberIdPipe, FormatDateIdPipe, FormatDateFull2IdPipe, FormatDateFull3IdPipe],
  templateUrl: './statement.component.html',
  styleUrl: './statement.component.scss'
})
export class StatementComponent implements OnInit, OnDestroy {
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    public data: DataService,
    private auth: AuthService,
    private request: RequestService
  ) { }

  faCircleChevronLeft: IconProp = faCircleChevronLeft;
  faCircleChevronRight: IconProp = faCircleChevronRight;
  private statementResultInterval: Subscription = new Subscription();

  footerJSONData: any = this.data.footerJSONData;
  footerData: any = [];

  statement: any = {
    monthly: {
      id: null,
      result: null
    },
    provider: {
      id: null,
      result: null
    },
    detail: {
      id: null,
      result: null
    }
  }

  month: any = {
    code: 0,
    value: dayjs().add(0, 'month').format('MMMM YYYY')
  }

  subtitle: any = '';

  page: any = {
    monthly: 1,
    provider: 1,
    detail: 1
  };
  limit: number = 10;
  pagination: any = {
    monthly: [],
    provider: [],
    detail: []
  }

  providerDate: string = '';
  providerId: string = '';
  providerName: string = '';

  changeMonth(value: number) {
    this.month = {
      code: value,
      value: dayjs().add(value, 'month').format('MMMM YYYY')
    }
    this.providerDate = '';
    this.providerId = '';
    this.providerName = '';
    this.getDataMonthly(1);
  }

  getStatementResult(id: string, type: string, date: any) {
    if (isPlatformBrowser(this.platformId)) {
      let page: number = 1;
      if (type === 'monthly') {
        page = this.page.monthly;
      } else if (type === 'provider') {
        page = this.page.provider;
      } else {
        page = this.page.detail;
      }

      const dateQuery = date;

      this.statementResultInterval = interval(2000).subscribe(val =>
        this.request.getStatementResult(this.auth.sessionValue, id, { date: dateQuery }, { page: page, limit: this.limit }).subscribe({
          next: (v: any) => {
            // console.log(v)
            this.statementResultInterval.unsubscribe();
            if (v['code'] === 200) {
              if (v.data.status !== 2) {
                this.getStatementResult(id, type, date);
              } else {
                if (type === 'monthly') {
                  this.statement.monthly.result = v;
                } else if (type === 'provider') {
                  this.statement.provider.result = v;
                } else {
                  this.statement.detail.result = v;
                  this.pagination.detail = [];
                  for (let i = 1; i <= this.statement.detail.result.data.result.pagination.max_page; i++) {
                    this.pagination.detail.push(i);
                  }
                }
              }
            }
          },
          error: (e) => {
            this.statementResultInterval.unsubscribe();
            console.log(e);
          },
          complete: () => { }
        })
      );
    }
  }

  getDataMonthly(page: number) {
    this.statement = {
      monthly: {
        id: null,
        result: null
      },
      provider: {
        id: null,
        result: null
      },
      detail: {
        id: null,
        result: null
      }
    };

    this.page = {
      monthly: page,
      provider: 1,
      detail: 1
    };

    this.subtitle = '';

    this.request.getStatementMonthly(this.auth.sessionValue, { date: { start: dayjs(this.month.value, 'MMMM YYYY').set('date', 1).format('YYYY-MM-DD'), end: dayjs(this.month.value, 'MMMM YYYY').set('date', 1).add(1, 'month').subtract(1, 'day').format('YYYY-MM-DD') } }).subscribe({
      next: (v: any) => {
        if (v.code == 200) {
          this.statement.monthly.id = v;
          const dateQuery = { start: dayjs(this.month.value, 'MMMM YYYY').set('date', 1).format('YYYY-MM-DD'), end: dayjs(this.month.value, 'MMMM YYYY').set('date', 1).add(1, 'month').subtract(1, 'day').format('YYYY-MM-DD') }
          this.getStatementResult(this.statement.monthly.id.data._id, 'monthly', dateQuery);
        }
      },
      error: (e) => {
        console.log(e.error.messages[0].message);
      }
    });
  }

  providerClick(date: string) {
    this.providerDate = date;
    this.providerId = '';
    this.providerName = '';
    this.getDataProvider(1);
  }

  getDataProvider(page: number) {
    this.statement.provider = {
      id: null,
      result: null
    };
    this.statement.detail = {
      id: null,
      result: null
    };

    this.page.provider = page;
    this.page.detail = 1;

    this.subtitle = ' - ' + dayjs(this.providerDate, 'YYYY-MM-DD').format('DD/MMM/YYYY');

    this.request.getStatementProvider(this.auth.sessionValue, { date: { start: dayjs(this.providerDate, 'YYYY-MM-DD').format('YYYY-MM-DD'), end: dayjs(this.providerDate, 'YYYY-MM-DD').format('YYYY-MM-DD') } }).subscribe({
      next: (v: any) => {
        if (v.code == 200) {
          this.statement.provider.id = v;
          console.log(dayjs(this.providerDate, 'YYYY-MM-DD').format('YYYY-MM-DD'));
          const dateQuery = { "start": dayjs(this.providerDate, 'YYYY-MM-DD').format('YYYY-MM-DD'), "end": dayjs(this.providerDate, 'YYYY-MM-DD').format('YYYY-MM-DD') }
          this.getStatementResult(this.statement.provider.id.data._id, 'provider', dateQuery);
        }
      },
      error: (e) => {
        console.log(e.error.messages[0].message);
      }
    });
  }

  detailClick(id: string, name: string) {
    this.providerId = id;
    this.providerName = name;
    this.getDataDetail(1);
  }

  getDataDetail(page: number) {
    this.statement.detail = {
      id: null,
      result: null
    };

    this.page.detail = page;

    this.subtitle = ' - ' + dayjs(this.providerDate, 'YYYY-MM-DD').format('DD/MMM/YYYY') + ' - ' + this.providerName;

    this.request.getStatementDetail(this.auth.sessionValue, { provider: this.providerId, date: { start: dayjs(this.providerDate, 'YYYY-MM-DD').format('YYYY-MM-DD'), end: dayjs(this.providerDate, 'YYYY-MM-DD').format('YYYY-MM-DD') } }, { page: this.page.detail, limit: this.limit }).subscribe({
      next: (v: any) => {
        if (v.code == 200) {
          this.statement.detail.id = v;
          const dateQuery = { "start": dayjs(this.providerDate, 'YYYY-MM-DD').format('YYYY-MM-DD'), "end": dayjs(this.providerDate, 'YYYY-MM-DD').format('YYYY-MM-DD') }
          this.getStatementResult(this.statement.detail.id.data._id, 'detail', dateQuery);
        }
      },
      error: (e) => {
        console.log(e.error.messages[0].message);
      }
    });
  }

  clickBack() {
    if (this.statement.detail.id !== null) {
      this.getDataProvider(1);
    } else if (this.statement.provider.id !== null) {
      this.getDataMonthly(1);
    }
  }

  pageChange(page: number, type: string) {
    if (type === 'monthly') {
      this.getDataMonthly(this.page.monthly);
    } else if (type === 'provider') {
      this.getDataProvider(page);
    } else {
      this.getDataDetail(page);
    }
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.getDataMonthly(1);
      const footerJSONData: any = this.data.footerJSONData;
      for (const value of footerJSONData.records) {
        if (value.type.toLowerCase() === 'default') {
          if (value.contents.id !== '') {
            if (value.contents.id.indexOf('<h1>') === 0 && value.contents.id.indexOf('</h1>') > -1) {
              this.footerData = {
                title: value.contents.id.substring(4, value.contents.id.indexOf('</h1>')),
                content: value.contents.id.substring(value.contents.id.indexOf('</h1>') + 5)
              }
            } else {
              this.footerData = {
                title: '',
                content: value.contents.id
              }
            }

          } else {
            if (value.contents.en.indexOf('<h1>') === 0 && value.contents.en.indexOf('</h1>') > -1) {
              this.footerData = {
                title: value.contents.en.substring(4, value.contents.en.indexOf('</h1>')),
                content: value.contents.en.substring(value.contents.en.indexOf('</h1>') + 5)
              }
            } else {
              this.footerData = {
                title: '',
                content: value.contents.en
              }
            }
          }
        }
        if (value.type.toLowerCase() === 'statement') {
          if (value.contents.id !== '') {
            if (value.contents.id.indexOf('<h1>') === 0 && value.contents.id.indexOf('</h1>') > -1) {
              this.footerData = {
                title: value.contents.id.substring(4, value.contents.id.indexOf('</h1>')),
                content: value.contents.id.substring(value.contents.id.indexOf('</h1>') + 5)
              }
            } else {
              this.footerData = {
                title: '',
                content: value.contents.id
              }
            }

          } else {
            if (value.contents.en.indexOf('<h1>') === 0 && value.contents.en.indexOf('</h1>') > -1) {
              this.footerData = {
                title: value.contents.en.substring(4, value.contents.en.indexOf('</h1>')),
                content: value.contents.en.substring(value.contents.en.indexOf('</h1>') + 5)
              }
            } else {
              this.footerData = {
                title: '',
                content: value.contents.en
              }
            }
          }
        }
      }
    }
  }

  ngOnDestroy(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.statementResultInterval.unsubscribe();
    }
  }
}
