import { Component, inject, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@Component({
  selector: 'app-deposit-popup-message',
  standalone: true,
  imports: [FormsModule, FontAwesomeModule],
  templateUrl: './deposit-popup-message.component.html',
  styleUrl: './deposit-popup-message.component.scss'
})
export class DepositPopupMessageComponent {
  constructor() { }

  @Input() transaction_note: any = null;
  modal = inject(NgbActiveModal);

  popupClose() {
    this.modal.close(this.transaction_note);
  }

  changeTransferSlip(event: any): void {
    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = () => {
      this.transaction_note = reader.result;
    };
  }
}
