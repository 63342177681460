import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { HeaderComponent } from "@template/mps-basic/header/header.component";
import { FooterComponent } from '@template/mps-basic/footer/footer.component';
import { DataService } from '@componentpath/services/data.service';
import { AuthService } from '@componentpath/services/auth.service';
import { RequestService } from '@componentpath/services/request.service';
import { SidebarMenuComponent } from '@template/mps-basic/parts/sidebar-menu/sidebar-menu.component';
import { Subscription } from 'rxjs';
import dayjs from 'dayjs';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCircleChevronLeft, faCircleChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FormatNumberIdPipe } from '@componentpath/pipes/format-number-id.pipe';
import { FormatDateFullIdPipe } from '@componentpath/pipes/format-date-id.pipe';

@Component({
  selector: 'app-saldo',
  standalone: true,
  imports: [HeaderComponent, FooterComponent, SidebarMenuComponent, FontAwesomeModule, FormatNumberIdPipe, FormatDateFullIdPipe],
  templateUrl: './saldo.component.html',
  styleUrl: './saldo.component.scss'
})
export class SaldoComponent {
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    public data: DataService,
    private auth: AuthService,
    private request: RequestService
  ) { }

  faCircleChevronLeft: IconProp = faCircleChevronLeft;
  faCircleChevronRight: IconProp = faCircleChevronRight;

  balanceHistory: any = null;

  footerJSONData: any = this.data.footerJSONData;
  footerData: any = [];

  subscription: Subscription = new Subscription();
  balance: any = null;

  type: any = '';
  created_at: any = '';

  page: number = 1;
  limit: number = 10;
  pagination: Array<any> = [];

  subscriptionData() {
    this.subscription.add(this.auth.getBalancevalue.subscribe({
      next: (v) => {
        this.balance = Math.floor(v);
      }
    }));
  }

  typeLabel(value: any) {
    let label: any = '-';
    const codes: any = {
      "83001": "Deposit",
      "83002": "Withdrawal",
      "83004": "Withdrawal Ditolak",
      "50001": "Transfer Masuk",
      "50002": "Transfer Keluar",
      "700xx": "Seamless Play",
      "800xx": "Referral",
      "801xx": "Tournament",
      "80201": "Promosi Rollingan",
      "80202": "Promosi Cashback",
      "80203": "Promosi Deposit",
      "80204": "Promosi Airdrop",
      "80205": "Promosi Spinwheel",
      "00001": "Initial Balance",
      "30001": "Deposit",
      "30002": "Withdrawal",
      "30003": "Reject Deposit",
      "30004": "Reject Withdrawal",
      "30005": "Approve Deposit",
      "30006": "Approve Withdrawal",
      // "50001": "Transfer In",
      // "50002": "Transfer Out",
      "50003": "Refund Transfer In",
      "50004": "Refund Transfer Out",
      "50009": "(V1) Refund Transfer In",
      // "700xx": "Seamless",
      "70001": "Seamless Bet",
      "70002": "Seamless Result",
      "70003": "Seamless Refund",
      "70004": "Seamless Bonus",
      "70005": "Seamless Jackpot",
      "70006": "Seamless Promo",
      "70007": "Seamless Transfer In",
      "70008": "Seamless Transfer Out",
      "70009": "Seamless Update",
      "70010": "Tournament Entry",
      "70011": "Cancel Tournament Entry",
      "70012": "Tournament Result",
      // "800xx": "Referral",
      // "801xx": "Tournament",
      "802xx": "Promotion",
      // "80201": "Global Cashback Promotion",
      // "80202": "Cashback Promotion",
      // "80203": "Deposit Cashback Promotion",
      "803xx": "Airdrop",
      // "83001": "(V1) Deposit",
      // "83002": "(V1) Withdrawal",
      // "83004": "(V1) Reject Withdrawal",
      "85001": "(V1) Transfer In",
      "85002": "(V1) Transfer Out",
      "85009": "(V1) Refund Transfer In",
      "90001": "(System) Manual Adjustment"
    };
    for (const [key, value2] of Object.entries(codes)) {
      if (value.toString() === key) {
        label = value2;
        break;
      }
    }
    return label;
  }

  saldoCurrent(saldoBefore: any, saldo: any) {
    return parseInt(saldoBefore) + parseInt(saldo);
  }

  pageChange(page: number) {
    this.page = page;
    this.getData();
  }

  typeGenerate(value: any) {
    let type = '';
    for (let i = 1; i <= 99; i++) {
      if (type) {
        type += ',';
      }

      let j = '70000';
      if (i < 10) {
        j = value + '0' + i;
      } else {
        j = value + i.toString();
      }

      type += j;
    }

    return type;
  }

  typeChange(event: any) {
    this.page = 1;
    if (event.target.value !== '700xx' && event.target.value !== '800xx' && event.target.value !== '801xx') {
      this.type = event.target.value;
    } else {
      if (event.target.value === '700xx') {
        this.type = this.typeGenerate('700');
      } else if (event.target.value === '800xx') {
        this.type = this.typeGenerate('800');
      } else if (event.target.value === '801xx') {
        this.type = this.typeGenerate('801');
      }
    }
    this.getData();
  }

  dateChange(event: any) {
    this.page = 1;
    if (event.target.value === '') {
      this.created_at = '';
    } else if (event.target.value === 'today') {
      this.created_at = {
        start: dayjs().format('YYYY-MM-DD'),
        end: dayjs().format('YYYY-MM-DD')
      };
    } else if (event.target.value === '7Before') {
      this.created_at = {
        start: dayjs().subtract(7, 'day').format('YYYY-MM-DD'),
        end: dayjs().format('YYYY-MM-DD')
      };
    } else if (event.target.value === '14Before') {
      this.created_at = {
        start: dayjs().subtract(14, 'day').format('YYYY-MM-DD'),
        end: dayjs().format('YYYY-MM-DD')
      };
    } else if (event.target.value === 'weekNow') {
      this.created_at = {
        start: dayjs().subtract(dayjs().day(), 'day').format('YYYY-MM-DD'),
        end: dayjs().add(6 - (dayjs().day()), 'day').format('YYYY-MM-DD')
      };
    } else if (event.target.value === 'weekBefore') {
      this.created_at = {
        start: dayjs().subtract((dayjs().day() + 7), 'day').format('YYYY-MM-DD'),
        end: dayjs().subtract(((6 - (dayjs().day())) + 7), 'day').format('YYYY-MM-DD')
      };
    }
    this.getData();
  }

  getData() {
    this.balanceHistory = null;
    const userProfileData: any = this.auth.userProfileData;
    this.request.getBalanceHistory(this.auth.sessionValue, userProfileData._id, { type: this.type, created_at: this.created_at }, { page: this.page, limit: this.limit }).subscribe({
      next: (v: any) => {
        if (v.code == 200) {
          this.pagination = [];
          this.balanceHistory = v;
          for (let i = 1; i <= this.balanceHistory.data.max_page; i++) {
            this.pagination.push(i);
          }
        }
      },
      error: (e) => {
        console.log(e.error.messages[0].message);
      }
    });
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.subscriptionData();
      this.getData();

      const footerJSONData: any = this.data.footerJSONData;
      for (const value of footerJSONData.records) {
        if (value.type.toLowerCase() === 'default') {
          if (value.contents.id !== '') {
            if (value.contents.id.indexOf('<h1>') === 0 && value.contents.id.indexOf('</h1>') > -1) {
              this.footerData = {
                title: value.contents.id.substring(4, value.contents.id.indexOf('</h1>')),
                content: value.contents.id.substring(value.contents.id.indexOf('</h1>') + 5)
              }
            } else {
              this.footerData = {
                title: '',
                content: value.contents.id
              }
            }

          } else {
            if (value.contents.en.indexOf('<h1>') === 0 && value.contents.en.indexOf('</h1>') > -1) {
              this.footerData = {
                title: value.contents.en.substring(4, value.contents.en.indexOf('</h1>')),
                content: value.contents.en.substring(value.contents.en.indexOf('</h1>') + 5)
              }
            } else {
              this.footerData = {
                title: '',
                content: value.contents.en
              }
            }
          }
        }
        if (value.type.toLowerCase() === 'saldo') {
          if (value.contents.id !== '') {
            if (value.contents.id.indexOf('<h1>') === 0 && value.contents.id.indexOf('</h1>') > -1) {
              this.footerData = {
                title: value.contents.id.substring(4, value.contents.id.indexOf('</h1>')),
                content: value.contents.id.substring(value.contents.id.indexOf('</h1>') + 5)
              }
            } else {
              this.footerData = {
                title: '',
                content: value.contents.id
              }
            }

          } else {
            if (value.contents.en.indexOf('<h1>') === 0 && value.contents.en.indexOf('</h1>') > -1) {
              this.footerData = {
                title: value.contents.en.substring(4, value.contents.en.indexOf('</h1>')),
                content: value.contents.en.substring(value.contents.en.indexOf('</h1>') + 5)
              }
            } else {
              this.footerData = {
                title: '',
                content: value.contents.en
              }
            }
          }
        }
      }
    }
  }

  ngOnDestroy(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.subscription.unsubscribe();
    }
  }
}
