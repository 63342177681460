import { CommonModule, isPlatformBrowser } from '@angular/common';
import { Component, Inject, PLATFORM_ID, inject, HostListener } from '@angular/core';
import { Router, RouterOutlet, NavigationEnd, ActivatedRoute } from '@angular/router';
import { DataService } from '@componentpath/services/data.service';
import { AuthService } from '@componentpath/services/auth.service';
import { ScriptsService } from '@componentpath/services/scripts.service';
import { LoginComponent } from '@template/mps-basic/login/login.component';
import { PinComponent } from '@template/mps-basic/pin/pin.component';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { Subscription } from 'rxjs';
import { NgbModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LoginPopupComponent } from '@template/mps-basic/parts/login-popup/login-popup.component';
import { PinPopupComponent } from '@template/mps-basic/parts/pin-popup/pin-popup.component';
import { RegisterPopupComponent } from '@template/mps-basic/parts/register-popup/register-popup.component';
import { PopupComponent } from '@template/mps-basic/parts/popup/popup.component';
import { DevPopupComponent } from '@template/mps-basic/parts/dev-popup/dev-popup.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet, NgbModule, LoginComponent, PinComponent, DevPopupComponent, FontAwesomeModule],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  title = 'orianna';
  bindingData: any;
  someInterval: any;
  subscription: Subscription = new Subscription();
  islogin: boolean = false;
  loaded: boolean = false;
  faChevronUp: IconProp = faChevronUp;
  currentScreenSize: string = '';

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private data: DataService,
    private auth: AuthService,
    private cookie: SsrCookieService,
    private scriptsService: ScriptsService,
    private breakpointObserver: BreakpointObserver,
    public router: Router,
    public route: ActivatedRoute,
  ) {
    this.checkScreen();
  }

  checkScreen() {
    let displayNameMap = new Map([
      [Breakpoints.XSmall, 'xSmall'],
      [Breakpoints.Small, 'small'],
      ["(min-width: 960px) and (max-width: 1365.98px)", 'customTablet'],
    ]);
    this.breakpointObserver.observe([
      Breakpoints.XSmall,
      Breakpoints.Small,
      "(min-width: 960px) and (max-width: 1365.98px)",
      "(min-width: 1366px)"
    ]).subscribe(result => {
      // console.log(result)
      for (const query of Object.keys(result.breakpoints)) {
        if (result.breakpoints[query]) {
          this.currentScreenSize = displayNameMap.get(query) ?? 'desktop';
        }
      }
    });
  }

  private modalService = inject(NgbModal);
  url: string = '';

  isShow: boolean = false;
  showDevMode: boolean = true;
  topPosToStartShowing = 100;
  currentVarURL: string = ''

  showPopup: boolean = true;

  @HostListener('window:scroll')
  checkScroll() {
    const scrollPosition = window.scrollY || document.documentElement.scrollTop || document.body.scrollTop || 0;

    if (scrollPosition >= this.topPosToStartShowing) {
      this.isShow = true;
    } else {
      this.isShow = false;
    }
  }

  gotoTop() {
    if (isPlatformBrowser(this.platformId)) {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    }
  }

  open() {
    if (this.url.search('/login') > -1) {
      this.modalService.open(LoginPopupComponent, { size: 'lg', keyboard: false, centered: true, backdrop: 'static' });
      this.showPopup = false;
    }
    if (this.url.search('/pin') > -1) {
      this.modalService.open(PinPopupComponent, { size: 'lg', keyboard: false, centered: true, backdrop: 'static' });
      this.showPopup = false;
    }
    if (this.url.search('/register') > -1) {
      this.modalService.open(RegisterPopupComponent, { size: 'xl', keyboard: false, centered: true, backdrop: 'static' });
      this.showPopup = false;
    }
  }

  close() {
    this.modalService.dismissAll();
  }

  ngOnInit(): void {
    this.auth.updateLoginStatus();
    this.data.getFileJSON();

    if (isPlatformBrowser(this.platformId)) {
      // this.metaService.setMetaWebsite();
      this.scriptsService.createGoogleScript(this.data.websiteJSONData['script']['thirdparty']['google_analytic']);
      this.scriptsService.createMetaScript(this.data.websiteJSONData['script']['thirdparty']['facebook_id']);
      this.scriptsService.createKwaiScript(this.data.websiteJSONData['script']['thirdparty']['kwai_id']);
      this.scriptsService.createBigoScript(this.data.websiteJSONData['script']['thirdparty']['bigo_id']);
      //chats
      this.scriptsService.createLivechat(this.data.websiteJSONData['contact']);
      this.scriptsService.createPureChat(this.data.websiteJSONData['contact']);
      this.scriptsService.createTawkto(this.data.websiteJSONData['contact']);

      if (this.currentScreenSize === "xSmall") {
        if (this.data.websiteJSONData['contact']) {
          this.scriptsService.hideChat(this.data.websiteJSONData['contact']);
        }
      }

      if (this.auth.sessionValue) {
        this.auth.callSession(this.auth.sessionValue);
      }
      this.auth.getUserData();

      this.route.queryParams.subscribe(values => {
        if (values['referral']) {
          this.cookie.set('ref', values['referral'], this.data.websiteConfigData.cookie_options);
        }
      });
      //test

      this.router.events.subscribe((val: any) => {
        if (val instanceof NavigationEnd) {
          window.scrollTo(0, 0);  // Scroll to the top of the page
          this.currentVarURL = val.url;

          if (this.currentScreenSize !== 'xSmall') {
            if (val.url.search('/play') === 0 || val.url.search('/register') === 0 || val.url.search('/login') === 0) {
              if (isPlatformBrowser(this.platformId)) {
                this.scriptsService.hideChat(this.data.websiteJSONData['contact']);
              }
            } else {
              if (isPlatformBrowser(this.platformId)) {
                this.scriptsService.showChat(this.data.websiteJSONData['contact']);
              }
            }
          }

          if (val.url.search('/play') === 0 || val.url.search('/register') === 0 || val.url.search('/login') === 0) {
            this.scriptsService.removeCustomScript();
          } else {
            this.scriptsService.displayCustomScript();
          }

          if (val.type === 1) {
            this.url = val.url;
            if (val.url.search('/login') > -1 || val.url.search('/pin') > -1 || val.url.search('/register') > -1) {
              this.open();
            } else {
              this.close();
            }

            if (this.currentVarURL === '/') {
              if (!this.cookie.get('popup001') && this.data.popupsJSONData) {
                if (this.showPopup) {
                  // console.log('a', val.url.search('/register'))
                  this.modalService.open(PopupComponent, { size: 'lg', keyboard: false, backdrop: 'static' });
                  this.modalService.activeInstances.subscribe((s) => {
                    this.showPopup = false;
                    this.cookie.set('popup001', '1', { expires: 1 });
                  })
                }
              }
            }
            if (val.url.search('/') > -1 || val.url.search('/deposit') > -1 || val.url.search('/withdrawal') > -1) {
              if (this.showDevMode) {
                this.modalService.open(DevPopupComponent, { size: 'lg', keyboard: false, centered: true, backdrop: 'static' });
                this.modalService.activeInstances.subscribe((s) => {
                  this.showDevMode = false;
                })
              }
            }
          }
        }

        // console.log('val.url : ', val.url);
        // if (val.url) {
        //   if (val.url.search('/play') === -1) {
        //     this.scriptsService.createLivechat(this.data.websiteJSONData['contact']);
        //     this.scriptsService.createPureChat(this.data.websiteJSONData['contact']);
        //     this.scriptsService.createTawkto(this.data.websiteJSONData['contact']);
        //   }
        // }


      });
    }
  }
  ngOnDestroy(): void {
    if (isPlatformBrowser(this.platformId)) {
      // this.subscription.unsubscribe();
    }
  }
}
