import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { HeaderComponent } from "@template/mps-basic/header/header.component";
import { FooterComponent } from '@template/mps-basic/footer/footer.component';
import { DataService } from '@componentpath/services/data.service';
import { SidebarMenuComponent } from '@template/mps-basic/parts/sidebar-menu/sidebar-menu.component';
import { ActivatedRoute, RouterModule } from '@angular/router';

@Component({
  selector: 'app-promotion',
  standalone: true,
  imports: [HeaderComponent, FooterComponent, SidebarMenuComponent, RouterModule],
  templateUrl: './promotion.component.html',
  styleUrl: './promotion.component.scss'
})
export class PromotionComponent {
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    public data: DataService,
    private activatedRoute: ActivatedRoute
  ) { }

  footerJSONData: any = this.data.footerJSONData;
  footerData: any = [];
  promo: any = null;
  promoJSONData: any = this.data.promoJSONData;
  promoData: any = [];

  public clickBack(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.promo = null;
    }
  }

  public promoDetail(data: any): void {
    if (isPlatformBrowser(this.platformId)) {
      this.promo = data;
    }
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      console.log(this.promoJSONData);
      if (this.promoJSONData['general'] !== null && this.promoJSONData['general'] !== undefined) {
        this.promoData = this.promoJSONData['general'];
      } else {
        this.promoData = [];
      }

      this.activatedRoute.queryParams
        .subscribe(params => {
          if (params['_id'] !== undefined) {
            for (const value of this.promoJSONData['general']) {
              if (value._id === params['_id']) {
                this.promo = value;
                break;
              }
            }
          } else {
            this.promo = null;
          }
        }
        );

      const footerJSONData: any = this.data.footerJSONData;
      for (const value of footerJSONData.records) {
        if (value.type.toLowerCase() === 'default') {
          if (value.contents.id !== '') {
            if (value.contents.id.indexOf('<h1>') === 0 && value.contents.id.indexOf('</h1>') > -1) {
              this.footerData = {
                title: value.contents.id.substring(4, value.contents.id.indexOf('</h1>')),
                content: value.contents.id.substring(value.contents.id.indexOf('</h1>') + 5)
              }
            } else {
              this.footerData = {
                title: '',
                content: value.contents.id
              }
            }

          } else {
            if (value.contents.en.indexOf('<h1>') === 0 && value.contents.en.indexOf('</h1>') > -1) {
              this.footerData = {
                title: value.contents.en.substring(4, value.contents.en.indexOf('</h1>')),
                content: value.contents.en.substring(value.contents.en.indexOf('</h1>') + 5)
              }
            } else {
              this.footerData = {
                title: '',
                content: value.contents.en
              }
            }
          }
        }
        if (value.type.toLowerCase() === 'promotion') {
          if (value.contents.id !== '') {
            if (value.contents.id.indexOf('<h1>') === 0 && value.contents.id.indexOf('</h1>') > -1) {
              this.footerData = {
                title: value.contents.id.substring(4, value.contents.id.indexOf('</h1>')),
                content: value.contents.id.substring(value.contents.id.indexOf('</h1>') + 5)
              }
            } else {
              this.footerData = {
                title: '',
                content: value.contents.id
              }
            }

          } else {
            if (value.contents.en.indexOf('<h1>') === 0 && value.contents.en.indexOf('</h1>') > -1) {
              this.footerData = {
                title: value.contents.en.substring(4, value.contents.en.indexOf('</h1>')),
                content: value.contents.en.substring(value.contents.en.indexOf('</h1>') + 5)
              }
            } else {
              this.footerData = {
                title: '',
                content: value.contents.en
              }
            }
          }
        }
      }
    }
  }
}
