<div class="modal-header">
    <h4 class="modal-title fs-14">Tambah Catatan</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="popupClose()"></button>
</div>
<div class="modal-body">
    <div class="align-items-center">
        <div class="bg-blue p-4 mb-3 rounded">
            <div class="row mb-4 fs-12">
                <div class="col-12 mb-3">
                    <textarea class="form-control input-blue" rows="3" placeholder="Catatan"
                        [(ngModel)]="transaction_note" name="transaction_note"></textarea>
                </div>
                <div class="col-12">
                    <div class="btn btn-blue btn-block" (click)="popupClose()">
                        Simpan
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>