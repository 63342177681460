import { Component, Input, PLATFORM_ID, Inject } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faBars, faHouse, faMagnifyingGlass, faPhone, faCaretDown, faCaretUp, faXmark, faWallet, faDownload, faPercent, faNetworkWired, faLock, faArrowRightFromBracket, faRightToBracket, faDiceFive, faGift, faBook, faMoneyBillTransfer } from '@fortawesome/free-solid-svg-icons';
import { faClock, faUser } from '@fortawesome/free-regular-svg-icons';
import dayjs from 'dayjs';
import { NgbDropdownModule, NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';
import { DataService } from '@componentpath/services/data.service';
import { AuthService } from '@componentpath/services/auth.service';
import { RouterModule, ActivatedRoute, Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { Subscription } from 'rxjs';
import { BreakpointObserver } from '@angular/cdk/layout';
import { RequestService } from '@componentpath/services/request.service';
import { FormatNumberIdPipe } from '@componentpath/pipes/format-number-id.pipe';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [RouterModule, FontAwesomeModule, NgbDropdownModule, NgbCollapseModule, FormatNumberIdPipe],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent {
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    public data: DataService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private auth: AuthService,
    private request: RequestService,
    private breakpointObserver: BreakpointObserver
  ) {
    // this.checkScreen();
  }

  currentScreenSize: string = '';

  @Input() activeMenu: string = 'home';
  @Input() latestNewsMobile: boolean = true;
  faBars: IconProp = faBars;
  faXmark: IconProp = faXmark;
  faClock: IconProp = faClock;
  faHouse: IconProp = faHouse;
  faMagnifyingGlass: IconProp = faMagnifyingGlass;
  faPhone: IconProp = faPhone;
  faCaretDown: IconProp = faCaretDown;
  faCaretUp: IconProp = faCaretUp;
  faUser: IconProp = faUser;
  faWallet: IconProp = faWallet;
  faDownload: IconProp = faDownload;
  faPercent: IconProp = faPercent;
  faNetworkWired: IconProp = faNetworkWired;
  faLock: IconProp = faLock;
  faArrowRightFromBracket: IconProp = faArrowRightFromBracket;
  faRightToBracket: IconProp = faRightToBracket;
  faDiceFive: IconProp = faDiceFive;
  faGift: IconProp = faGift;
  faBook: IconProp = faBook;
  faMoneyBillTransfer: IconProp = faMoneyBillTransfer;

  sidenav: boolean = false;
  websiteJSONData: any = this.data.websiteJSONData;

  isCollapsed: boolean = true;

  slotShow: boolean = false;
  casinoShow: boolean = false;
  arcadeShow: boolean = false;
  sportbookShow: boolean = false;

  searchValue: string = '';

  islogin: boolean = false;
  subscription: Subscription = new Subscription();
  profile: any = null;
  balance: any = null;

  newstickerData: any = null;
  categoryProviderData: any = null;

  time: string = dayjs().format('DD-MM-YYYY HH:mm') + ' (GMT' + dayjs().format('Z)').replace(':00', '');

  getTime = Date.now();

  showMenuSlots: any = {
    menu: false,
    submenu: false
  };
  showMenuLiveCasino: any = {
    menu: false,
    submenu: false
  };
  showMenuFishHunter: any = {
    menu: false,
    submenu: false
  };
  showMenuSportbook: any = {
    menu: false,
    submenu: false
  };

  // getTime() {
  //   return Date.now();
  // }

  referralRuleJSONData: any = this.data.referralRuleJSONData;
  userGorupData: any = this.auth.userGorupData;

  checkScreen() {
    let displayNameMap = new Map([
      ['(min-width: 0px) and (max-width: 991.98px)', 'mobile'],
      ['(min-width: 992px)', 'desktop']
    ]);
    this.breakpointObserver.observe([
      "(min-width: 0px) and (max-width: 991.98px)",
      "(min-width: 992px)"
    ]).subscribe(result => {
      for (const query of Object.keys(result.breakpoints)) {
        if (result.breakpoints[query]) {
          this.currentScreenSize = displayNameMap.get(query) ?? 'desktop';
        }
      }
    });
  }

  sidenavToggle(): void {
    this.sidenav = !this.sidenav;
  }

  showMenu(menu: string, type: string): void {
    if (menu === 'slots') {
      if (type === 'menu') {
        this.showMenuSlots.menu = true;
      } else {
        this.showMenuSlots.submenu = true;
      }
    } else if (menu === 'live-casino') {
      if (type === 'menu') {
        this.showMenuLiveCasino.menu = true;
      } else {
        this.showMenuLiveCasino.submenu = true;
      }
    } else if (menu === 'fish-hunter') {
      if (type === 'menu') {
        this.showMenuFishHunter.menu = true;
      } else {
        this.showMenuFishHunter.submenu = true;
      }
    } else if (menu === 'sportbook') {
      if (type === 'menu') {
        this.showMenuSportbook.menu = true;
      } else {
        this.showMenuSportbook.submenu = true;
      }
    }
  }

  hideMenu(menu: string, type: string): void {
    if (menu === 'all-submenu') {
      this.showMenuSlots.submenu = false;
      this.showMenuLiveCasino.submenu = false;
      this.showMenuFishHunter.submenu = false;
      this.showMenuSportbook.submenu = false;
    } else if (menu === 'slots') {
      setTimeout(() => {
        if (type === 'menu') {
          this.showMenuSlots.menu = false;
        } else {
          this.showMenuSlots.submenu = false;
        }
      }, 100);
    } else if (menu === 'live-casino') {
      setTimeout(() => {
        if (type === 'menu') {
          this.showMenuLiveCasino.menu = false;
        } else {
          this.showMenuLiveCasino.submenu = false;
        }
      }, 100);
    } else if (menu === 'fish-hunter') {
      setTimeout(() => {
        if (type === 'menu') {
          this.showMenuFishHunter.menu = false;
        } else {
          this.showMenuFishHunter.submenu = false;
        }
      }, 100);
    } else if (menu === 'sportbook') {
      setTimeout(() => {
        if (type === 'menu') {
          this.showMenuSportbook.menu = false;
        } else {
          this.showMenuSportbook.submenu = false;
        }
      }, 100);
    }
  }

  subscriptionData() {
    this.subscription.add(this.auth.getLoginStatus.subscribe({
      next: (v) => {
        this.islogin = v;
        this.profile = this.auth.userProfileData;
      }
    }));
    this.subscription.add(this.auth.getBalancevalue.subscribe({
      next: (v) => {
        this.balance = Math.floor(v);
      }
    }));
  }

  public redirectLogin(): void {
    this.sidenavToggle();
    this.router.navigate(['/login'], { queryParams: { url: this.router.url } });
  }

  public toggleShow(type: string) {
    if (type === 'slot') {
      this.slotShow = !this.slotShow;
    } else if (type === 'casino') {
      this.casinoShow = !this.casinoShow;
    } else if (type === 'arcade') {
      this.arcadeShow = !this.arcadeShow;
    } else if (type === 'sportbook') {
      this.sportbookShow = !this.sportbookShow;
    }
  }

  filterSearchChange(event: any) {
    this.router.navigate(['/search'], { queryParams: { value: this.searchValue } });
  }

  updateSearchValue(event: any) {
    this.searchValue = event.target.value;
  }

  // logout(): void {
  //   this.sidenavToggle();
  //   this.auth.setLogout();
  //   this.router.navigate(['/']);
  // }

  public logout(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.request.logout(this.auth.sessionValue).subscribe({
        next: (response) => {
          if (response.code === 200 && response.data.status) {
            this.sidenavToggle();
            this.auth.setLogout();
          } else {
            console.error('Logout failed:', response.message);
          }
        },
        error: (error) => {
          console.error('Logout error:', error);
        },
        complete: () => {
          window.location.reload();
          // this.router.navigate(['/']);
          // console.log(this.auth.sessionValue);
        },
      });
    }
  }

  // getTime() {
  //   return Date.now();
  // }

  ngOnInit(): void {
    this.checkScreen();
    if (isPlatformBrowser(this.platformId)) {
      const newstickerJSONData: any = this.data.newstickerJSONData;
      this.newstickerData = [];
      for (const value of newstickerJSONData) {
        this.newstickerData.push(value);
      }

      const categoryproviderJSONData: any = this.data.categoryproviderJSONData;
      this.categoryProviderData = {};
      this.categoryProviderData['slots'] = categoryproviderJSONData[0];
      this.categoryProviderData['live-casino'] = categoryproviderJSONData[1];
      this.categoryProviderData['fish-hunter'] = categoryproviderJSONData[2];
      this.categoryProviderData['sportbook'] = categoryproviderJSONData[3];

      this.subscriptionData();
      this.activatedRoute.queryParams
        .subscribe(params => {
          if (this.router.url.search("/search") > -1) {
            if (params['search'] !== undefined) {
              this.searchValue = params['search'];
            }
          }
        }
        );
    }
  }

  ngOnDestroy(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.subscription.unsubscribe();
    }
  }
}
