<div class="page-saldo ticker-none">
    <app-header activeMenu="saldo" [latestNewsMobile]="false" />
    <div class="page-inner">
        <div class="container-xxl row m-auto">
            <div class="col-lg-2">
                <app-sidebar-menu [active]="'saldo'" />
            </div>
            <div class="page-inner-container col-lg-10 col-12">
                <div class="title mb-2">
                    SALDO
                </div>
                <div class="balance mt-1 text-white">
                    Balance <span class="balance-value ms-2">
                        @if(balance < 0) { <div class="spinner-border spinner-border-sm">
                </div>
                } @else {
                IDR {{balance | formatNumberId}}
                }
                </span>
            </div>
            <div class="saldo-container mt-5">
                <div class="row mb-4 mx-0">
                    <div class="col-6 ps-0">
                        <div>
                            <label class="form-label">Type</label>
                            <select class="form-select" (change)="typeChange($event)">
                                <option value="">All</option>
                                <option value="30001">Deposit</option>
                                <option value="30002">Withdrawal</option>
                                <option value="30003">Deposit Ditolak</option>
                                <option value="30004">Withdrawal Ditolak</option>
                                <option value="30005">Deposit Diterima</option>
                                <option value="30006">Withdrawal Diterima</option>
                                <option value="50001">Transfer Masuk</option>
                                <option value="50002">Transfer Keluar</option>
                                <option value="70001">Seamless Bet</option>
                                <option value="70002">Seamless Result</option>
                                <option value="70003">Seamless Refund</option>
                                <option value="70004">Seamless Bonus</option>
                                <option value="70005">Seamless Jackpot</option>
                                <option value="70006">Seamless Promo</option>
                                <option value="70007">Seamless Transfer In</option>
                                <option value="70008">Seamless Transfer Out</option>
                                <option value="70009">Seamless Update</option>
                                <option value="70010">Tournament Entry</option>
                                <option value="70011">Cancel Tournament Entry</option>
                                <option value="70012">Tournament Result</option>
                                <option value="80001">Referral</option>
                                <option value="80101">Tournament</option>
                                <option value="80301">Air Drop</option>
                                <option value="80201">Promosi Rollingan</option>
                                <option value="80202">Promosi Cashback</option>
                                <option value="80203">Promosi Deposit</option>
                                <option value="80204">Promosi Airdrop</option>
                                <option value="80205">Promosi Spinwheel</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-6 pe-0">
                        <div>
                            <label class="form-label">Pilih Tanggal</label>
                            <select class="form-select" (change)="dateChange($event)">
                                <option value="">All</option>
                                <option value="today">Hari Ini</option>
                                <option value="7Before">7 Hari yang lalu</option>
                                <option value="14Before">14 Hari yang lalu</option>
                                <option value="weekNow">Minggu Ini</option>
                                <option value="weekBefore">Minggu sebelumnya</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="table-responsive">
                    <table class="table">
                        <thead>
                            <tr>
                                <th scope="col" [width]="100">Tipe</th>
                                <th scope="col">Saldo Sebelum</th>
                                <th scope="col">Saldo</th>
                                <th scope="col">Saldo Sesudah</th>
                                <th scope="col">Tanggal</th>
                            </tr>
                        </thead>
                        <tbody>
                            @if(balanceHistory === null) {
                            <tr>
                                <td colspan="5" align="center">
                                    <div class="spinner-border"></div>
                                </td>
                            </tr>
                            } @else {
                            @if(balanceHistory.data.total_records === 0) {
                            <tr>
                                <td colspan="5" align="center">
                                    No Data
                                </td>
                            </tr>
                            } @else {
                            @for (item of balanceHistory.data.records; track item;) {
                            <tr>
                                <td><span class="badge badge-pill badge-info">{{item.description[0]}}</span></td>
                                <td>IDR {{item.balance_before | formatNumberId}}</td>
                                <td>IDR {{item.balance | formatNumberId}}</td>
                                <td>IDR {{saldoCurrent(item.balance_before,item.balance) | formatNumberId}}</td>
                                <td>{{item.created_at | formatDateFullId}}</td>
                            </tr>
                            }
                            }
                            }
                        </tbody>
                        @if(balanceHistory !== null) {
                        <caption>
                            <div class="row">
                                <div class="col-6">
                                    @if(balanceHistory.data.total_records > 0) {
                                    Showing
                                    <b>{{((balanceHistory.data.page-1)*balanceHistory.data.limit)+1}}</b>-<b>{{balanceHistory.data.page*balanceHistory.data.limit}}</b>
                                    from <b>{{balanceHistory.data.total_records}}</b> data
                                    }
                                </div>
                                <div class="col-6">
                                    @if(balanceHistory.data.max_page > 1) {
                                    <ul class="pagination">
                                        <li class="page-item" [class.disabled]="page===1" (click)="pageChange(1)">
                                            <button class="page-link">Previous</button>
                                        </li>
                                        @for (item of pagination; track item;) {
                                        <li class="page-item" (click)="pageChange(item)">
                                            <button class="page-link" [class.active]="page===item">{{item}}</button>
                                        </li>
                                        }
                                        <li class="page-item" [class.disabled]="page===balanceHistory.data.max_page"
                                            (click)="pageChange(balanceHistory.data.max_page)">
                                            <button class="page-link">Next</button>
                                        </li>
                                    </ul>
                                    }
                                </div>
                            </div>
                        </caption>
                        }
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
<app-footer [data]="footerData" />
</div>