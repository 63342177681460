<div class="page-contact ticker-none">
    <app-header activeMenu="contact" [latestNewsMobile]="false" />
    <div class="page-inner">
        <div class="container-xxl row m-auto">
            <div class="col-lg-2">
                <app-sidebar-menu [active]="'contact'" />
            </div>
            <div class="page-inner-container col-lg-10 col-12">
                <div class="title mb-2">
                    HUBUNGI KAMI
                </div>
                <div class="contact-container row px-2 px-lg-4 mt-4 gx-lg-4 gx-3">
                    @if(websiteJSONData.contact.facebook !== '') {
                    <div class="col-lg-3 col-6 mb-lg-4 mb-3">
                        <a [href]="'https://www.facebook.com/people/'+websiteJSONData.contact.facebook" target="_blank">
                            <div class="contact-item">
                                <div class="contact-item-left icon-facebook">
                                    <fa-icon class="icon" [icon]="faFacebook"></fa-icon>
                                </div>
                                <div class="contact-item-right">
                                    <div class="info" #infoFacebook>
                                        <div class="name name-facebook">
                                            Facebook
                                        </div>
                                        <div class="value" #valueFacebook
                                            [class.animate]="valueFacebookWidth > infoFacebookWidth">
                                            {{websiteJSONData.contact.facebook}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                    }
                    @if(websiteJSONData.contact.instagram !== '') {
                    <div class="col-lg-3 col-6 mb-lg-4 mb-3">
                        <a [href]="'https://www.instagram.com/'+websiteJSONData.contact.instagram" target="_blank">
                            <div class="contact-item">
                                <div class="contact-item-left icon-instagram">
                                    <fa-icon class="icon" [icon]="faInstagram"></fa-icon>
                                </div>
                                <div class="contact-item-right">
                                    <div class="info" #infoInstagram>
                                        <div class="name name-instagram">
                                            Instagram
                                        </div>
                                        <div class="value" #valueInstagram
                                            [class.animate]="valueInstagramWidth > infoInstagramWidth">
                                            {{websiteJSONData.contact.instagram}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                    }
                    @if(websiteJSONData.contact.line !== '') {
                    <div class="col-lg-3 col-6 mb-lg-4 mb-3">
                        <a [href]="'https://www.line.me/'+websiteJSONData.contact.line" target="_blank">
                            <div class="contact-item">
                                <div class="contact-item-left icon-line">
                                    <fa-icon class="icon" [icon]="faLine"></fa-icon>
                                </div>
                                <div class="contact-item-right">
                                    <div class="info" #infoLine>
                                        <div class="name name-line">
                                            Line
                                        </div>
                                        <div class="value" #valueLine [class.animate]="valueLineWidth > infoLineWidth">
                                            {{websiteJSONData.contact.line}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                    }
                    @if(websiteJSONData.contact.linkedin !== '') {
                    <div class="col-lg-3 col-6 mb-lg-4 mb-3">
                        <a [href]="'https://www.linkedin.com/in/'+websiteJSONData.contact.linkedin" target="_blank">
                            <div class="contact-item">
                                <div class="contact-item-left icon-linkedin">
                                    <fa-icon class="icon" [icon]="faLinkedin"></fa-icon>
                                </div>
                                <div class="contact-item-right">
                                    <div class="info" #infoLinkedin>
                                        <div class="name name-linkedin">
                                            Linkedin
                                        </div>
                                        <div class="value" #valueLinkedin
                                            [class.animate]="valueLinkedinWidth > infoLinkedinWidth">
                                            {{websiteJSONData.contact.linkedin}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                    }
                    @if(websiteJSONData.contact.livechat.engine !== '') {
                    <div class="col-lg-3 col-6 mb-lg-4 mb-3">
                        <div class="contact-item" (click)="setLivechatURL()">
                            <div class="contact-item-left icon-facebook">
                                <fa-icon class="icon" [icon]="faHeadphones"></fa-icon>
                            </div>
                            <div class="contact-item-right">
                                <div class="info" #infoLivechat>
                                    <div class="name">
                                        Livechat
                                    </div>
                                    <div class="value" #valueLivechat
                                        [class.animate]="valueLivechatWidth > infoLivechatWidth">
                                        {{websiteJSONData.contact.livechat.engine}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    }
                    @if(websiteJSONData.contact.phone !== '') {
                    <div class="col-lg-3 col-6 mb-lg-4 mb-3">
                        <a [href]="'tel:'+websiteJSONData.contact.phone_area_code+websiteJSONData.contact.linkedin">
                            <div class="contact-item">
                                <div class="contact-item-left icon-facebook">
                                    <fa-icon class="icon" [icon]="faPhone"></fa-icon>
                                </div>
                                <div class="contact-item-right">
                                    <div class="info" #infoPhone>
                                        <div class="name">
                                            Phone
                                        </div>
                                        <div class="value" #valuePhone
                                            [class.animate]="valuePhoneWidth > infoPhoneWidth">
                                            {{websiteJSONData.contact.phone_area_code}}{{websiteJSONData.contact.phone}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                    }
                    @if(websiteJSONData.contact.reddit !== '') {
                    <div class="col-lg-3 col-6 mb-lg-4 mb-3">
                        <a [href]="'https://www.reddit.com/'+websiteJSONData.contact.reddit" target="_blank">
                            <div class="contact-item">
                                <div class="contact-item-left icon-reddit">
                                    <fa-icon class="icon" [icon]="faReddit"></fa-icon>
                                </div>
                                <div class="contact-item-right">
                                    <div class="info" #infoReddit>
                                        <div class="name name-reddit">
                                            Reddit
                                        </div>
                                        <div class="value" #valueReddit
                                            [class.animate]="valueRedditWidth > infoRedditWidth">
                                            {{websiteJSONData.contact.reddit}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                    }
                    @if(websiteJSONData.contact.telegram !== '') {
                    <div class="col-lg-3 col-6 mb-lg-4 mb-3">
                        <a [href]="'https://t.me/'+websiteJSONData.contact.telegram" target="_blank">
                            <div class="contact-item">
                                <div class="contact-item-left icon-telegram">
                                    <fa-icon class="icon" [icon]="faTelegram"></fa-icon>
                                </div>
                                <div class="contact-item-right">
                                    <div class="info" #infoTelegram>
                                        <div class="name name-telegram">
                                            Telegram
                                        </div>
                                        <div class="value" #valueTelegram
                                            [class.animate]="valueTelegramWidth > infoTelegramWidth">
                                            {{websiteJSONData.contact.telegram}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                    }
                    @if(websiteJSONData.contact.twitter !== '') {
                    <div class="col-lg-3 col-6 mb-lg-4 mb-3">
                        <a [href]="'https://twitter.com/'+websiteJSONData.contact.twitter" target="_blank">
                            <div class="contact-item">
                                <div class="contact-item-left icon-twitter">
                                    <fa-icon class="icon" [icon]="faTwitter"></fa-icon>
                                </div>
                                <div class="contact-item-right">
                                    <div class="info" #infoTwitter>
                                        <div class="name name-twitter">
                                            Twitter
                                        </div>
                                        <div class="value" #valueTwitter
                                            [class.animate]="valueTwitterWidth > infoTwitterWidth">
                                            {{websiteJSONData.contact.twitter}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                    }
                    @if(websiteJSONData.contact.whatsapp !== '') {
                    <div class="col-lg-3 col-6 mb-lg-4 mb-3">
                        <a [href]="'https://wa.me/'+websiteJSONData.contact.whatsapp" target="_blank">
                            <div class="contact-item">
                                <div class="contact-item-left icon-whatsapp">
                                    <fa-icon class="icon" [icon]="faWhatsapp"></fa-icon>
                                </div>
                                <div class="contact-item-right">
                                    <div class="info" #infoWhatsapp>
                                        <div class="name name-whatsapp">
                                            Whatsapp
                                        </div>
                                        <div class="value" #valueWhatsapp
                                            [class.animate]="valueWhatsappWidth > infoWhatsappWidth">
                                            {{websiteJSONData.contact.whatsapp}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                    }
                    @if(websiteJSONData.contact.youtube !== '') {
                    <div class="col-lg-3 col-6 mb-lg-4 mb-3">
                        <a [href]="'https://www.youtube.com/channel/'+websiteJSONData.contact.youtube" target="_blank">
                            <div class="contact-item">
                                <div class="contact-item-left icon-youtube">
                                    <fa-icon class="icon" [icon]="faYoutube"></fa-icon>
                                </div>
                                <div class="contact-item-right">
                                    <div class="info" #infoYoutube>
                                        <div class="name name-youtube">
                                            Youtube
                                        </div>
                                        <div class="value" #valueYoutube
                                            [class.animate]="valueYoutubeWidth > infoYoutubeWidth">
                                            {{websiteJSONData.contact.youtube}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                    }
                </div>
            </div>
        </div>
    </div>
    <app-footer [data]="footerData" />
</div>