<div class="page-saldo ticker-none">
    <app-header activeMenu="transaction" [latestNewsMobile]="false" />
    <div class="page-inner">
        <div class="container-xxl row m-auto">
            <div class="col-lg-2">
                <app-sidebar-menu [active]="'transaction'" />
            </div>
            <div class="page-inner-container col-lg-10 col-12">
                <div class="title mb-2">
                    TRANSACTION @if(this.transactionDetailLoading === false) {<span
                        class="badge badge-pill badge-info ms-2">{{typeLabel(this.transactionDetail.data.type)}}</span>}
                </div>
                @if(this.transactionDetailLoading === false) {
                <button class="back btn btn-primary text-white" (click)="clickBack()">
                    << Back </button>
                        }
                        <div class="saldo-container row mt-4 text-white">
                            <div class="row mb-4 mx-0 filter">
                                <div class="col-4 ps-0">
                                    <div>
                                        <label class="form-label">Type</label>
                                        <select class="form-select" (change)="typeChange($event)">
                                            <option value="0">All</option>
                                            <option value="1">Deposit</option>
                                            <option value="2">Withdrawal</option>
                                            <option value="3">Transfer In</option>
                                            <option value="4">Transfer Out</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div>
                                        <label class="form-label">Pilih Tanggal</label>
                                        <select class="form-select" (change)="dateChange($event)">
                                            <option value="">All</option>
                                            <option value="today">Hari Ini</option>
                                            <option value="7Before">7 Hari yang lalu</option>
                                            <option value="14Before">14 Hari yang lalu</option>
                                            <option value="weekNow">Minggu Ini</option>
                                            <option value="weekBefore">Minggu sebelumnya</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-4 pe-0">
                                    <div>
                                        <label class="form-label">Status</label>
                                        <select class="form-select" (change)="statusChange($event)">
                                            <option value="">All</option>
                                            <option value="1">Pending</option>
                                            <option value="2">Success</option>
                                            <option value="3">Failed</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            @if(this.transactionDetailLoading === null) {
                            <div class="px-3">
                                <div class="table-responsive">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">Tipe</th>
                                                <th scope="col">Metode Pembayaran</th>
                                                <th scope="col">Jumlah</th>
                                                <th scope="col">Status</th>
                                                <th scope="col">Tanngal</th>
                                                <th scope="col">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            @if(transaction === null) {
                                            <tr>
                                                <td colspan="6" align="center">
                                                    <div class="spinner-border"></div>
                                                </td>
                                            </tr>
                                            } @else {
                                            @if(transaction.data.total_records === 0) {
                                            <tr>
                                                <td colspan="5" align="center">
                                                    No Data
                                                </td>
                                            </tr>
                                            } @else {
                                            @for (item of transaction.data.records; track item;) {
                                            <tr>
                                                <td>{{typeLabel(item.type)}}</td>
                                                <td>{{item.payment_method !== undefined ?
                                                    paymentMethodLabel(item.payment_method) : '-'}}</td>
                                                <td>IDR {{item.amount | formatNumberId}}</td>
                                                <td>{{statusLabel(item.status)}}</td>
                                                <td>{{item.created_at | formatDateFullId}}</td>
                                                <td>
                                                    @if(item.payment_method !== undefined &&
                                                    paymentMethodLabel(item.payment_method) === 'QRIS' &&
                                                    statusLabel(item.status) === 'Pending') {
                                                    <button class="btn btn-dark btn-sm" (click)="refresh()"><fa-icon
                                                            [icon]="faRotateRight"></fa-icon></button>
                                                    } @else {
                                                    <button class="btn btn-primary btn-sm"
                                                        (click)="clickDetail(item._id)">View</button>
                                                    }
                                                </td>
                                            </tr>
                                            }
                                            }
                                            }
                                        </tbody>
                                        @if(transaction !== null) {
                                        <caption>
                                            <div class="row">
                                                <div class="col-6">
                                                    @if(transaction.data.total_records > 0) {
                                                    Showing
                                                    <b>{{((transaction.data.page-1)*transaction.data.limit)+1}}</b>-<b>{{transaction.data.page*transaction.data.limit}}</b>
                                                    from <b>{{transaction.data.total_records}}</b> data
                                                    }
                                                </div>
                                                <div class="col-6">
                                                    @if(transaction.data.max_page > 1) {
                                                    <ul class="pagination">
                                                        <li class="page-item" [class.disabled]="page===1"
                                                            (click)="pageChange(1)">
                                                            <button class="page-link">Previous</button>
                                                        </li>
                                                        @for (item of pagination; track item;) {
                                                        <li class="page-item" (click)="pageChange(item)">
                                                            <button class="page-link"
                                                                [class.active]="page===item">{{item}}</button>
                                                        </li>
                                                        }
                                                        <li class="page-item"
                                                            [class.disabled]="page===transaction.data.max_page"
                                                            (click)="pageChange(transaction.data.max_page)">
                                                            <button class="page-link">Next</button>
                                                        </li>
                                                    </ul>
                                                    }
                                                </div>
                                            </div>
                                        </caption>
                                        }
                                    </table>
                                </div>
                            </div>
                            } @else {
                            @if(this.transactionDetailLoading === true) {
                            <div class="row mb-4 mx-2 info-detail p-4">
                                <div class="spinner-border m-auto"></div>
                            </div>
                            } @else {
                            @if(this.transactionDetail.data.type === 1) {
                            <div class="row mb-4 mx-2 info-detail info-detail-deposit p-4">
                                <div class="col-4 ps-0">
                                    <div class="label">
                                        ID Transaksi
                                    </div>
                                    <div class="value">
                                        {{this.transactionDetail.data._id}}
                                    </div>
                                </div>
                                <div class="col-3">
                                    <div class="label">
                                        Tipe Transaksi
                                    </div>
                                    <div class="value">
                                        {{typeLabel(this.transactionDetail.data.type)}}
                                    </div>
                                </div>
                                <div class="col-3 pe-0">
                                    <div class="label">
                                        Rekening Penerima
                                    </div>
                                    <div class="value">
                                        {{this.transactionDetail.data.to_bank.system_bank.name}}<br />
                                        {{this.transactionDetail.data.to_bank.account_name}}<br />
                                        {{this.transactionDetail.data.to_bank.account_number}}<br />
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-4 mx-2 info-detail info-detail-deposit p-4">
                                <div class="col-4 ps-0">
                                    <div class="label">
                                        Jumlah
                                    </div>
                                    <div class="value">
                                        IDR {{this.transactionDetail.data.amount | formatNumberId}}
                                    </div>
                                </div>
                                <div class="col-3">
                                    <div class="label">
                                        Biaya Transaksi
                                    </div>
                                    <div class="value">
                                        IDR {{ this.transactionDetail.data.transaction_fee | formatNumberId}}
                                    </div>
                                </div>
                                <div class="col-3 pe-0">
                                    <div class="label">
                                        Jumlah Bersih
                                    </div>
                                    <div class="value">
                                        IDR {{this.transactionDetail.data.amount -
                                        this.transactionDetail.data.transaction_fee | formatNumberId}}
                                    </div>
                                </div>
                            </div>
                            } @else {
                            <div class="row mb-4 mx-2 info-detail p-4">
                                <div class="col-4 ps-0">
                                    <div class="label">
                                        ID Transaksi
                                    </div>
                                    <div class="value">
                                        {{this.transactionDetail.data._id}}
                                    </div>
                                </div>
                                <div class="col-3">
                                    <div class="label">
                                        Tipe Transaksi
                                    </div>
                                    <div class="value">
                                        {{typeLabel(this.transactionDetail.data.type)}}
                                    </div>
                                </div>
                                <div class="col-3 pe-0">
                                    <div class="label">
                                        Jumlah
                                    </div>
                                    <div class="value">
                                        IDR {{this.transactionDetail.data.amount | formatNumberId}}
                                    </div>
                                </div>
                            </div>
                            }
                            }
                            <div class="px-3">
                                <div class="table-responsive">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">Status</th>
                                                <th scope="col">Tanggal</th>
                                                <th scope="col">IP Address</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            @if(this.transactionDetailLoading === true) {
                                            <tr>
                                                <td colspan="3" align="center">
                                                    <div class="spinner-border"></div>
                                                </td>
                                            </tr>
                                            } @else {
                                            @if(this.transactionDetail.data.histories.length === 0) {
                                            <tr>
                                                <td colspan="3" align="center">
                                                    No Data
                                                </td>
                                            </tr>
                                            } @else {
                                            @for (item of this.transactionDetail.data.histories; track item;) {
                                            <tr>
                                                <td>{{statusLabel(item.status)}}</td>
                                                <td>{{item.created_at | formatDateFullId}}</td>
                                                <td>{{item.ip}}</td>
                                            </tr>
                                            }
                                            }
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            }
                        </div>
            </div>
        </div>
    </div>
    <app-footer [data]="footerData" />
</div>