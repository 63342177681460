<div class="page-deposit ticker-none">
    <app-header activeMenu="deposit" [latestNewsMobile]="false" />
    <div class="page-inner">
        <div class="container-xxl row m-auto">
            <div class="col-lg-2">
                <app-sidebar-menu [active]="'deposit'" />
            </div>
            <div class="page-inner-container col-lg-10 col-12">
                <div class="title mb-2">
                    DEPOSIT
                </div>
                @if(companyBankLoad === false || playerBankLoad === false) {
                <div class="text-center text-white pt-4">
                    <div class="spinner-border"></div>
                </div>
                } @else {
                @if(bank_info.company === null) {
                <div class="text-center text-white pt-4">
                    Payment Method is not Available
                </div>
                } @else {
                @if(successMessage !== '') {
                <div class="alert alert-success p-2" role="alert">
                    {{successMessage}}
                </div>
                }
                @if(errorMessage !== '') {
                <div class="alert alert-danger p-2" role="alert">
                    {{errorMessage}}
                </div>
                }
                <form (submit)="createDepositSubmit()">
                    <div class="saldo-container row px-2 my-3">
                        @if(currentScreenSize === 'desktop') {
                        <div class="col-lg-6 col-12 ps-0 mb-4">
                            <div class="card1">
                                <div class="card-inner">
                                    <div class="row mb-4">
                                        <div class="col-lg-6 col-12 mb-lg-0 mb-2">
                                            @if (bank_info.company !== null) {
                                            {{bank_info.company._id !== 'qris' ? bank_info.company.system_bank.name :
                                            'QRIS'}}
                                            } @else {
                                            &nbsp;
                                            }
                                        </div>
                                        <div class="col-lg-6 col-12 mt-lg-0 mt-2">
                                            <div class="ml-auto">
                                                @if (bank_info.company !== null) {
                                                @if(bank_info.company.system_bank !== undefined &&
                                                bank_info.company.system_bank.type === 4 ||
                                                bank_info.company.qr_image !== undefined && bank_info.company.qr_image
                                                !== null){
                                                <div class="container-qr">
                                                    <img #parentImage
                                                        [src]="'../assets/orianna/images/bank/'+(bank_info.company | fileImageBank)+'?'+currentUnix"
                                                        [alt]="bank_info.company | fileImageBank">
                                                </div>
                                                } @else {
                                                @if(bank_info.company._id !== 'qris') {
                                                <img class="bg-white px-2"
                                                    [src]="bank_info.company.system_bank.images.small" />
                                                } @else {
                                                <div class="container-qr"><img #parentImage
                                                        src="../../template/mps-basic/assets/orianna/images/payment-qris.webp" />
                                                </div>
                                                }
                                                }
                                                }@else {
                                                &nbsp;
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div class="account-name">
                                        @if (bank_info.company !== null) {
                                        {{bank_info.company._id !== 'qris' ? bank_info.company.account_name : ''}}
                                        }@else {
                                        &nbsp;
                                        }
                                    </div>
                                    <div class="account-number">
                                        @if (bank_info.company !== null) {
                                        @if(bank_info.company.system_bank !== undefined &&
                                        bank_info.company.system_bank.type !== 4 || bank_info.company.qr_image !==
                                        undefined && bank_info.company.qr_image ===
                                        null){
                                        {{bank_info.company._id !== 'qris' ? bank_info.company.account_number:
                                        ''}}
                                        }
                                        } @else {
                                        &nbsp;
                                        }
                                    </div>
                                </div>
                                <div class="change-account" (click)="popupOpen('bankCompany')">
                                    @if (bank_info.company !== null) {
                                    GANTI REKENING TUJUAN
                                    } @else {
                                    &nbsp;
                                    }
                                </div>
                            </div>
                            @if(bank_info.company !== null && bank_info.company._id !== 'qris'){
                            <div class="card-sep my-4"></div>
                            <div class="form-group mb-3">
                                <label class="label text-white mb-2">Rekening Anda</label>
                                <div class="input-select-bank">
                                    <button type="button" class="form-control"
                                        (click)="popupOpen('bankPlayer')">{{bank_info.player.system_bank.name}}</button>
                                    <fa-icon [icon]="faChevronDown"></fa-icon>
                                </div>
                            </div>
                            <div class="form-group mb-3">
                                <label class="mb-2 label text-white">Nama rekening</label>
                                <div>
                                    <input type="text" class="form-control" placeholder="Nama rekening" disabled
                                        [value]="bank_info.player.account_name">
                                </div>
                            </div>
                            <div class="form-group mb-3">
                                <label class="mb-2 label text-white">Nomor rekening</label>
                                <div>
                                    <input type="text" class="form-control" placeholder="Nomor rekening" disabled
                                        [value]="bank_info.player.account_number">
                                </div>
                            </div>
                            }
                        </div>
                        <div class="col-lg-6 col-12">
                            <div class="form-group mb-4">
                                <label class="mb-2 text-white">Jumlah yang di Transfer*</label>
                                <input type="text" class="form-control input-blue input-deposit-amount"
                                    placeholder="Jumlah yang di Transfer" name="amount" (input)="filterNumber($event)"
                                    (keyup)="amountKeyup($event)" [(ngModel)]="amountNumberFormat">
                                <div class="text-small text-end text-white mt-1"><span class="font-italic me-2">Biaya
                                        Admin : </span> IDR {{fee | formatNumberId}}</div>
                            </div>
                            @if(bank_info.company !== null && bank_info.company._id === 'qris') {
                            <div class="row mb-4">
                                <div class="col-12 mb-2">
                                    <div class="btn btn-yellow btn-block" (click)="popupOpen('promo')">PILIH
                                        PROMO</div>
                                </div>
                                @if(promo !== null) {
                                <div class="col-12 text-center mt-2 promotion-container">
                                    <div class="promotion-title">
                                        {{promo.title}}
                                    </div>
                                    <div class="promotion-type">
                                        {{promo.type}}
                                    </div>
                                    <div class="promotion-reset" (click)="promoReset()">
                                        <fa-icon [icon]="faClose"></fa-icon>
                                    </div>
                                </div>
                                }
                            </div>
                            <div class="form-group mb-4">
                                <label class="mb-2 text-yellow">Saldo yang Diterima</label>
                                <div ngbAccordion class="accordion-deposit">
                                    <div ngbAccordionItem [collapsed]="false">
                                        <h2 ngbAccordionHeader>
                                            <button ngbAccordionButton class="text-center">
                                                @if (total === '') {
                                                IDR 0
                                                } @else {
                                                {{total}}
                                                }
                                            </button>
                                        </h2>
                                        <div ngbAccordionCollapse>
                                            <div ngbAccordionBody>
                                                <ng-template>
                                                    <b>Rincian Deposit</b>
                                                    <div class="row">
                                                        <div class="col">
                                                            Jumlah yang di transfer
                                                        </div>
                                                        <div class="col text-end">
                                                            IDR {{amount | formatNumberId}}
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col">
                                                            Biaya Admin
                                                        </div>
                                                        <div class="col text-end">
                                                            IDR {{fee | formatNumberId}}
                                                        </div>
                                                    </div>
                                                    <hr />
                                                    <div class="row">
                                                        <div class="col">
                                                            Jumlah yang di dapat
                                                        </div>
                                                        <div class="col text-end">
                                                            @if (total === '') {
                                                            IDR 0
                                                            } @else {
                                                            {{total}}
                                                            }
                                                        </div>
                                                    </div>
                                                </ng-template>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            }
                            @if(bank_info.company !== null && bank_info.company._id !== 'qris') {
                            <div class="row mb-4">
                                <div class="col-lg-7 col-12 mb-lg-0 mb-2">
                                    <div class="btn-upload cursor-pointer" (click)="file_input.click()">
                                        <fa-icon [icon]="faCloudUpload"></fa-icon>
                                        <div class="btn btn-blue btn-block mt-2">
                                            Upload Bukti Bayar
                                        </div>
                                    </div>
                                    <input class="d-none" type="file" accept="image/*" #file_input
                                        (change)="changeTransferSlip($event)">
                                </div>
                                <div class="col-lg-5 col-12">
                                    <div class="btn-image">
                                        @if(transfer_slip === '') {
                                        <fa-icon [icon]="faImage"></fa-icon>
                                        } @else {
                                        <img [src]="transfer_slip" />
                                        }
                                    </div>
                                </div>
                            </div>
                            <textarea class="form-control input-blue mb-4" rows="3" placeholder="Catatan"
                                [(ngModel)]="transaction_note" name="transaction_note"></textarea>
                            <div class="row">
                                <div class="col-12">
                                    <div class="btn btn-yellow btn-block" (click)="popupOpen('promo')">PILIH
                                        PROMO</div>
                                </div>
                                @if(promo !== null) {
                                <div class="col-12 text-center mt-2 promotion-container">
                                    <div class="promotion-title">
                                        {{promo.title}}
                                    </div>
                                    <div class="promotion-type">
                                        {{promo.type}}
                                    </div>

                                    <div class="promotion-reset" (click)="promoReset()">
                                        <fa-icon [icon]="faClose"></fa-icon>
                                    </div>
                                </div>
                                }
                            </div>
                            <div class="form-group my-4">
                                <label class="mb-2 text-yellow">Saldo yang Diterima</label>
                                <div ngbAccordion class="accordion-deposit">
                                    <div ngbAccordionItem [collapsed]="false">
                                        <h2 ngbAccordionHeader>
                                            <button ngbAccordionButton class="text-center">
                                                @if (total === '') {
                                                IDR 0
                                                } @else {
                                                {{total}}
                                                }
                                            </button>
                                        </h2>
                                        <div ngbAccordionCollapse>
                                            <div ngbAccordionBody>
                                                <ng-template>
                                                    <b>Rincian Deposit</b>
                                                    <div class="row">
                                                        <div class="col">
                                                            Jumlah yang di transfer
                                                        </div>
                                                        <div class="col text-end">
                                                            IDR {{amount | formatNumberId}}
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col">
                                                            Biaya Admin
                                                        </div>
                                                        <div class="col text-end">
                                                            IDR {{fee | formatNumberId}}
                                                        </div>
                                                    </div>
                                                    <hr />
                                                    <div class="row">
                                                        <div class="col">
                                                            Jumlah yang di dapat
                                                        </div>
                                                        <div class="col text-end">
                                                            @if (total === '') {
                                                            IDR 0
                                                            } @else {
                                                            {{total}}
                                                            }
                                                        </div>
                                                    </div>
                                                </ng-template>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            }
                        </div>
                        } @else {
                        <div class="col-lg-6 col-12 ps-0 mb-lg-4 mb-3">
                            <div class="card1">
                                <div class="card-inner">
                                    <div class="account-name">
                                        @if (bank_info.company !== null) {
                                        {{bank_info.company._id !== 'qris' ? bank_info.company.account_name : 'Qris'}}
                                        }@else {
                                        &nbsp;
                                        }
                                    </div>
                                    <div class="account-number">
                                        @if (bank_info.company !== null) {
                                        @if(bank_info.company.system_bank !== undefined &&
                                        bank_info.company.system_bank.type !== 4 || bank_info.company.qr_image !==
                                        undefined && bank_info.company.qr_image ===
                                        null){
                                        {{bank_info.company._id !== 'qris' ? bank_info.company.account_number:
                                        ''}}
                                        }
                                        } @else {
                                        &nbsp;
                                        }
                                    </div>
                                    <div class="image-bank">
                                        @if (bank_info.company !== null) {
                                        @if(bank_info.company.system_bank !== undefined &&
                                        bank_info.company.system_bank.type === 4 ||
                                        bank_info.company.qr_image !== undefined && bank_info.company.qr_image
                                        !== null){
                                        <div class="container-qr">
                                            <img #parentImage
                                                [src]="'../assets/orianna/images/bank/'+(bank_info.company | fileImageBank)+'?'+currentUnix"
                                                [alt]="bank_info.company | fileImageBank">
                                        </div>
                                        } @else {
                                        @if(bank_info.company._id !== 'qris') {
                                        <img class="bg-white px-2" [src]="bank_info.company.system_bank.images.small" />
                                        } @else {
                                        <div class="container-qr"><img #parentImage
                                                src="../../template/mps-basic/assets/orianna/images/payment-qris.webp" />
                                        </div>
                                        }
                                        }
                                        }@else {
                                        &nbsp;
                                        }
                                    </div>
                                </div>
                                <div class="change-account" (click)="popupOpen('bankCompany')">
                                    @if (bank_info.company !== null) {
                                    GANTI REKENING TUJUAN
                                    } @else {
                                    &nbsp;
                                    }
                                </div>
                            </div>
                            @if(bank_info.company !== null && bank_info.company._id !== 'qris'){
                            <div class="card-sep my-3 my-lg-4"></div>
                            <div class="form-group">
                                <label class="label text-white mb-1">Rekening Anda</label>
                                <div class="input-select-bank" (click)="popupOpen('bankPlayer')">
                                    <button type="button" class="form-control">
                                        {{bank_info.player.system_bank.name}}
                                        <br />
                                        {{bank_info.player.account_name}} - {{bank_info.player.account_number}}
                                    </button>
                                    <img [src]="bank_info.player.system_bank.images.small" />
                                    <fa-icon [icon]="faChevronDown"></fa-icon>
                                </div>
                            </div>
                            }
                        </div>
                        <div class="col-lg-6 col-12">
                            <div class="float-start fs-12">
                                <label class="mb-1 text-white">Nominal Transaksi</label>
                            </div>
                            <div class="float-end fs-10">
                                <div class="text-small text-end text-white mb-1">
                                    <fa-icon [icon]="faCircleExclamation" class="me-2"></fa-icon> <span
                                        class="font-italic me-2">Biaya Transfer: </span> IDR {{fee |
                                    formatNumberId}}
                                </div>
                            </div>
                            <div class="clearfix gap-2 d-flex flex-wrap justify-content-between">
                                <div class="flex-fill mb-1 min-w-200">
                                    <div class="input-group">
                                        <span class="input-group-text fs-12">IDR</span>
                                        <input type="text" class="form-control text-end fs-11" name="amount"
                                            (input)="filterNumber($event)" (keyup)="amountKeyup($event)"
                                            [(ngModel)]="amountNumberFormat">
                                    </div>
                                </div>
                                <div class="flex-fill mb-3 h-30">
                                    <div class="btn btn-yellow btn-block fs-10" (click)="popupOpen('promo')">
                                        @if(promo === null) {
                                        <fa-icon [icon]="faPercent" class="me-2"></fa-icon> PILIH PROMO
                                        } @else {
                                        {{promo.title}} <fa-icon [icon]="faClose" class="ms-2"></fa-icon>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div class="clearfix row px-2">
                                @if(bank_info.company !== null && bank_info.company._id !== 'qris') {
                                <div class="col-6 mb-3 fs-10">
                                    <div class="btn btn-block btn-upload2" (click)="popupOpen('upload')">
                                        <fa-icon [icon]="faCloudUpload"></fa-icon> Upload Bukti Bayar
                                    </div>
                                </div>
                                <div class="col-6 fs-10">
                                    <div class="btn btn-block btn-plus h-30" (click)="popupOpen('message')">
                                        <fa-icon [icon]="faCirclePlus"></fa-icon> Tambah Catatan
                                    </div>
                                </div>
                                }
                            </div>
                            <div class="form-group mb-3">
                                <div ngbAccordion class="accordion-deposit fs-12">
                                    <div ngbAccordionItem [collapsed]="false" class="mb-0">
                                        <div ngbAccordionHeader>
                                            <button ngbAccordionButton class="p-0">
                                                <div class="row w-100">
                                                    <div class="col text-yellow fs-12">
                                                        Saldo yang Diterima
                                                    </div>
                                                    <div class="col text-white text-end fs-14">
                                                        @if (total === '') {
                                                        IDR 0
                                                        } @else {
                                                        {{total}}
                                                        }
                                                    </div>
                                                </div>
                                            </button>
                                        </div>
                                        <div ngbAccordionCollapse class="mt-2">
                                            <div ngbAccordionBody>
                                                <ng-template>
                                                    <b>Rincian Deposit</b>
                                                    <div class="row">
                                                        <div class="col">
                                                            Jumlah yang di transfer
                                                        </div>
                                                        <div class="col text-end">
                                                            IDR {{amount | formatNumberId}}
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col">
                                                            Biaya Admin
                                                        </div>
                                                        <div class="col text-end">
                                                            IDR {{fee | formatNumberId}}
                                                        </div>
                                                    </div>
                                                    <hr />
                                                    <div class="row">
                                                        <div class="col">
                                                            Jumlah yang di dapat
                                                        </div>
                                                        <div class="col text-end">
                                                            @if (total === '') {
                                                            IDR 0
                                                            } @else {
                                                            {{total}}
                                                            }
                                                        </div>
                                                    </div>
                                                </ng-template>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        }
                        <button type="submit" class="btn btn-blue btn-block"
                            [disabled]="isSubmit || bank_info === null">
                            Buat Deposit
                        </button>
                    </div>
                </form>
                }
                }
            </div>
        </div>
    </div>
    <app-footer [data]="footerData" />
</div>