<div class="page-saldo ticker-none">
    <app-header activeMenu="statement" [latestNewsMobile]="false" />
    <div class="page-inner">
        <div class="container-xxl row m-auto">
            <div class="col-lg-2">
                <app-sidebar-menu [active]="'statement'" />
            </div>
            <div class="page-inner-container col-lg-10 col-12">
                <div class="title mb-4">
                    STATEMENT
                    <div class="subtitle">
                        (Maksimal 3 Bulan)
                    </div>
                </div>
                @if(statement.provider.id !== null || statement.detail.id !== null) {
                <button class="back btn btn-primary text-white" (click)="clickBack()">
                    << Back</button>
                        }
                        <div class="saldo-container mt-4">
                            <div class="row mb-4">
                                <div class="col-4">
                                    <button class="btn btn-primary" [disabled]="month.code === -2"
                                        (click)="changeMonth(month.code-1)">
                                        << <span class="d-none d-lg-inline-block">Sebelumnya</span>
                                    </button>
                                </div>
                                <div class="col-4 text-white text-center month-current">
                                    {{month.value}}{{subtitle}}
                                </div>
                                <div class="col-4 text-end">
                                    <button class="btn btn-primary" [disabled]="month.code === 0"
                                        (click)="changeMonth(month.code+1)"> <span
                                            class="d-none d-lg-inline-block">Selanjutnya</span>
                                        >></button>
                                </div>
                            </div>
                            <div class="table-responsive">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            @if(statement.provider.id === null && statement.detail.id === null) {
                                            <th scope="col">Tanggal</th>
                                            <th scope="col">Turnover</th>
                                            <th scope="col">Win/Lose</th>
                                            <th scope="col">Action</th>
                                            } @else if(statement.provider.id !== null && statement.detail.id === null) {
                                            <th scope="col">Provider</th>
                                            <th scope="col">Turnover</th>
                                            <th scope="col">Win/Lose</th>
                                            <th scope="col">Action</th>
                                            } @else if(statement.provider.id !== null && statement.detail.id !== null) {
                                            <th scope="col">Provider</th>
                                            <th scope="col">Games</th>
                                            <th scope="col">Type</th>
                                            <th scope="col">Bet</th>
                                            <th scope="col">Win</th>
                                            <th scope="col">Win/Lose</th>
                                            <th scope="col">Play Time</th>
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        @if(statement.provider.id === null && statement.detail.id === null) {
                                        @if(statement.monthly.result === null) {
                                        <tr>
                                            <td colspan="4" align="center">
                                                <div class="spinner-border"></div>
                                            </td>
                                        </tr>
                                        } @else if(statement.monthly.result.data.result.records.length === 0) {
                                        <tr>
                                            <td colspan="4" align="center">
                                                No Data
                                            </td>
                                        </tr>
                                        } @else {
                                        @for (item of statement.monthly.result.data.result.records; track item;) {
                                        <tr>
                                            <td>{{item.played_datetime | formatDateFull3Id}}</td>
                                            <td>IDR {{item.turnover_amount | formatNumberId}}</td>
                                            <td>IDR {{item.win_lose_amount | formatNumberId}}</td>
                                            <td><button class="btn btn-primary btn-sm"
                                                    (click)="providerClick(item.played_datetime)">View</button></td>
                                        </tr>
                                        }
                                        }
                                        } @else if(statement.provider.id !== null && statement.detail.id === null) {
                                        @if(statement.provider.result === null) {
                                        <tr>
                                            <td colspan="4" align="center">
                                                <div class="spinner-border"></div>
                                            </td>
                                        </tr>
                                        } @else if(statement.provider.result.data.result.records.length === 0) {
                                        <tr>
                                            <td colspan="4" align="center">
                                                No Data
                                            </td>
                                        </tr>
                                        } @else {
                                        @for (item of statement.provider.result.data.result.records; track item;) {
                                        <tr>
                                            <td>{{item.provider_name}}</td>
                                            <td>IDR {{item.turnover_amount | formatNumberId}}</td>
                                            <td>IDR {{item.win_lose_amount | formatNumberId}}</td>
                                            <td><button class="btn btn-primary btn-sm"
                                                    (click)="detailClick(item.provider,item.provider_name)">View</button>
                                            </td>
                                        </tr>
                                        }
                                        }
                                        } @else if(statement.provider.id !== null && statement.detail.id !== null) {
                                        @if(statement.detail.result === null) {
                                        <tr>
                                            <td colspan="7" align="center">
                                                <div class="spinner-border"></div>
                                            </td>
                                        </tr>
                                        } @else if(statement.detail.result.data.result.records.length === 0) {
                                        <tr>
                                            <td colspan="7" align="center">
                                                No Data
                                            </td>
                                        </tr>
                                        } @else {
                                        @for (item of statement.detail.result.data.result.records; track item;) {
                                        <tr>
                                            <td>{{item.provider_name}}</td>
                                            <td>{{item.game_name}}</td>
                                            <td>{{item.game_category}}</td>
                                            <td>{{item.bet_amount | formatNumberId}}</td>
                                            <td>IDR {{item.winning_amount | formatNumberId}}</td>
                                            <td>IDR {{item.win_lose_amount | formatNumberId}}</td>
                                            <td>{{item.played_datetime | formatDateFull2Id}}</td>
                                        </tr>
                                        }
                                        }
                                        }
                                    </tbody>
                                    <caption>
                                        <div class="row">
                                            <div class="col-6">
                                                @if(statement.monthly.id !== null && statement.provider.id === null &&
                                                statement.detail.id === null) {
                                                @if(statement.monthly.result !== null) {
                                                Showing
                                                <b>1</b>-<b>{{statement.monthly.result.data.result.records.length}}</b>
                                                data
                                                }
                                                } @else if(statement.monthly.id !== null && statement.provider.id !==
                                                null
                                                && statement.detail.id === null) {
                                                @if(statement.provider.result !== null) {
                                                Showing
                                                <b>1</b>-<b>{{statement.provider.result.data.result.records.length}}</b>
                                                data
                                                }
                                                } @else if(statement.monthly.id !== null && statement.provider.id !==
                                                null
                                                && statement.detail.id !== null) {
                                                @if(statement.detail.result !== null) {
                                                Showing
                                                <b>{{((statement.detail.result.data.result.pagination.page-1)*statement.detail.result.data.result.pagination.limit)+1}}</b>-<b>{{statement.detail.result.data.result.pagination.page*statement.detail.result.data.result.pagination.limit}}</b>
                                                from
                                                <b>{{statement.detail.result.data.result.pagination.total_records}}</b>
                                                data
                                                }
                                                }
                                            </div>
                                            <div class="col-6">
                                                @if(statement.monthly.result !== null && statement.provider.result !==
                                                null
                                                && statement.detail.result !== null &&
                                                statement.detail.result.data.result.pagination.max_page > 1) {
                                                <ul class="pagination">
                                                    <li class="page-item" [class.disabled]="page===1"
                                                        (click)="pageChange(1, 'detail')">
                                                        <button class="page-link">Previous</button>
                                                    </li>
                                                    @for (item of pagination.detail;
                                                    track item;) {
                                                    <li class="page-item" (click)="pageChange(item, 'detail')">
                                                        <button class="page-link"
                                                            [class.active]="page.detail===item">{{item}}</button>
                                                    </li>
                                                    }
                                                    <li class="page-item"
                                                        [class.disabled]="page===statement.detail.result.data.result.pagination.max_page"
                                                        (click)="pageChange(statement.detail.result.data.result.pagination.max_page, 'detail')">
                                                        <button class="page-link">Next</button>
                                                    </li>
                                                </ul>
                                                }
                                            </div>
                                        </div>
                                    </caption>
                                </table>
                            </div>
                        </div>
            </div>
        </div>
    </div>
    <app-footer [data]="footerData" />
</div>